import React from 'react'
import { Card, Row, Col, Menu } from 'antd';
import LegalLayout from '../../components/legal';
import { Link } from 'gatsby';
import SEO from '../../components/seo';

const ServiceAgreement = (props) => {
    return (
      <LegalLayout {...props}>
		<SEO title="Service Agreement" description='Terms and conditions of service agreement' />
          <h2 style={{fontSize:'28px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'prewrap'}}>
          Service <span style={{fontWeight:600}}>Agreement</span>
          </h2>
          <Card>
          	<h3>Welcome to RealtyStack</h3>
			<p>These terms and conditions outline the rules and regulations for the use of RealtyStack's Website.</p> <br /> 
			<br /> 
			<p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use RealtyStack's website 
			if you do not accept all of the terms and conditions stated on this page.</p>
			<p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
			and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website
			and accepting the Company's terms and conditions. "The Company", "Ourselves", "RealtyStack", "We", "Our" and "Us", refers
			to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client
			or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
			the process of our assistance to the Client in the most appropriate manner, whether by formal meetings
			of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect
			of provision of the Company's stated services/products, in accordance with and subject to, prevailing law
			of United States. Any use of the above terminology or other words in the singular, plural,
			capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p><h2>Cookies</h2>
			<p>We employ the use of cookies. By using RealtyStack's website you consent to the use of cookies 
			in accordance with RealtyStack's privacy policy.</p><p>Most of the modern day interactive web sites
			use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site
			to enable the functionality of this area and ease of use for those people visiting. Some of our 
			affiliate / advertising partners may also use cookies.</p><h2>License</h2>
			<p>Unless otherwise stated, RealtyStack and/or it's licensors own the intellectual property rights for
			all material on RealtyStack. All intellectual property rights are reserved. You may view and/or print
			pages from https://realtystack.io for your own personal use subject to restrictions set in these terms and conditions.</p>
			<p>You must not:</p>
			<ol>
				<li>Republish material from https://realtystack.io</li>
				<li>Sell, rent or sub-license material from https://realtystack.io</li>
				<li>Reproduce, duplicate or copy material from https://realtystack.io</li>
			</ol>
			<p>Redistribute content from RealtyStack (unless content is specifically made for redistribution).</p>
		<h2>Hyperlinking to our Content</h2>
			<ol>
				<li>The following organizations may link to our Web site without prior written approval:
					<ol>
					<li>Government agencies;</li>
					<li>Search engines;</li>
					<li>News organizations;</li>
					<li>Online directory distributors when they list us in the directory may link to our Web site in the same
						manner as they hyperlink to the Web sites of other listed businesses; and</li>
					<li>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls,
						and charity fundraising groups which may not hyperlink to our Web site.</li>
					</ol>
				</li>
			</ol>
			<ol start="2">
				<li>These organizations may link to our home page, to publications or to other Web site information so long
					as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or
					approval of the linking party and its products or services; and (c) fits within the context of the linking
					party's site.
				</li>
				<li>We may consider and approve in our sole discretion other link requests from the following types of organizations:
					<ol>
						<li>commonly-known consumer and/or business information sources such as Chambers of Commerce, American
							Automobile Association, AARP and Consumers Union;</li>
						<li>dot.com community sites;</li>
						<li>associations or other groups representing charities, including charity giving sites,</li>
						<li>online directory distributors;</li>
						<li>internet portals;</li>
						<li>accounting, law and consulting firms whose primary clients are businesses; and</li>
						<li>educational institutions and trade associations.</li>
					</ol>
				</li>
			</ol>
			<p>We will approve link requests from these organizations if we determine that: (a) the link would not reflect
			unfavorably on us or our accredited businesses (for example, trade associations or other organizations
			representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed
			to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from
			the visibility associated with the hyperlink outweighs the absence of RealtyStack; and (d) where the
			link is in the context of general resource information or is otherwise consistent with editorial content
			in a newsletter or similar product furthering the mission of the organization.</p>

			<p>These organizations may link to our home page, to publications or to other Web site information so long as
			the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval
			of the linking party and it products or services; and (c) fits within the context of the linking party's
			site.</p>

			<p>If you are among the organizations listed in paragraph 2 above and are interested in linking to our website,
			you must notify us by sending an e-mail to <a href="mailto:support@realtystack.io" title="send an email to support@realtystack.io">support@realtystack.io</a>.
			Please include your name, your organization name, contact information (such as a phone number and/or e-mail
			address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site,
			and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.</p>

			<p>Approved organizations may hyperlink to our Web site as follows:</p>

			<ol>
				<li>By use of our corporate name; or</li>
				<li>By use of the uniform resource locator (Web address) being linked to; or</li>
				<li>By use of any other description of our Web site or material being linked to that makes sense within the
					context and format of content on the linking party's site.</li>
			</ol>
			<p>No use of RealtyStack's logo or other artwork will be allowed for linking absent a trademark license
			agreement.</p>
		<h2>Iframes</h2>
			<p>Without prior approval and express written permission, you may not create frames around our Web pages or
			use other techniques that alter in any way the visual presentation or appearance of our Web site.</p>
		<h2>Reservation of Rights</h2>
			<p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
			link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also
			reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
			to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</p>
		<h2>Removal of links from our website</h2>
			<p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
			us about this. We will consider requests to remove links but will have no obligation to do so or to respond
			directly to you.</p>
			<p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
			or accuracy; nor do we commit to ensuring that the website remains available or that the material on the
			website is kept up to date.</p>
		<h2>Content Liability</h2>
			<p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
			and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
			page on your Web site or within any context containing content or materials that may be interpreted as
			libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
			other violation of, any third party rights.</p>
		<h2>Disclaimer</h2>
			<p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
			<ol>
			<li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
			<li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
			<li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
			<li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
			</ol>
			<p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
			are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
			in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
			(including negligence) and for breach of statutory duty.</p>
			<p>To the extent that the website and the information and services on the website are provided free of charge,
			we will not be liable for any loss or damage of any nature.</p>
		<h2></h2>
			<p></p>
		<h2>Credit & Contact Information</h2>
			<p>This Terms and conditions page was created at <a style={{color:'inherit', textDecoration:'none', cursor:'text'}}
				href="https://termsandconditionstemplate.com/generate/">termsandconditionstemplate.com</a> generator. If you have
			any queries regarding any of our terms, please contact us.</p>		
          </Card>
      </LegalLayout>
    )
}

export default ServiceAgreement;


// Terms of Use
// By using the cozy.co website and Cozy service (the "Service") you are agreeing to be bound by the following terms and conditions ("Terms of Use"). We've done our best to make the following stuff clear and easy to understand.
// The Service allows landlords to set up online rental applications for their properties, potential tenants to submit rental applications, and tenants to make online rent payments and, at the request of landlords, order and share credit reports and background check reports with landlords. In the future, you’ll be able to do even more on Cozy. The Service also provides access to renters insurance (through our affiliate Cozy Insurance Services, LLC, doing business in California as Cozy Home Insurance Agency) in select markets.
// Paying your rent with Cozy from your checking account (via the ACH network) is free for tenants. Payments by credit/debit card involve a processing fee of 2.75%, which will be added to the amount of your payment. A copy of your credit report and/or your background check report can be ordered and shared with a single prospective landlord for a one-time fee, as described on our Features & Pricing page. Cozy is currently free for landlords: there is no charge to receive rent payments or copies of credit or background check reports using the Service. If we ever change our fees or introduce new features that have additional fees, we’ll let you know first via email or the site. If you don’t want to pay the new fees, you can cancel service anytime. We don’t offer refunds for fees you’ve already paid.
// Cozy uses Stripe to execute online payment transactions. By using this feature of the Service, you agree to be bound by the Stripe Connected Account Agreement, available at https://stripe.com/connect/account-terms. This may be updated from time to time. For avoidance of doubt, if any payment you initiate using the Service does not successfully complete, the payee reserves the right to seek payment from you via or outside the Service. Any authorization you provide to make repeating automatic payments using the Service will remain in effect until cancelled.
// By providing us with your banking, credit/debt card, or other payment information, you authorize us to use it and disclose it to Stripe or other payment gateway providers for the purpose of processing the payments you authorize on the Service. Payment gateway providers may also aggregate your information for other purposes, but only in ways that don’t identify you personally or disclose any personal information about you, and may disclose your personal information when necessary to comply with the law.
// We never take custody of money you transfer using the Service and we’re not responsible for what recipients do with the payments you make. For example, we’re not responsible for your landlord returning your deposit. If a payor or payor’s bank or card issuer initiates a reversal, chargeback, or dispute of a payment made to you, you authorize us and our third-party payment providers to reverse or otherwise debit funds from your account in accordance with applicable financial institution and network policies and procedures. In the event we are unable to reverse or otherwise debit funds from your account, you agree promptly to deposit such funds upon our request. We may also initiate a reversal or other debit, or take other actions we determine to be appropriate, if we believe fraud or other abuse of the Service has occurred.
// Using Cozy Pay™, you can also use the Service to send payments to landlords who have not yet signed up with Cozy. In such cases, your name and checking account information will be transmitted to our check-printing partner, who will print and mail a check drawn against your account on your behalf. By using Cozy Pay, you authorize us to share your account information in this manner. Please note that Cozy is not responsible for delays associated with postal delivery or any resulting late fees or penalties for which you may be responsible. We are also not responsible for any losses resulting from third-party fraud or other crimes committed in connection with the interception or mis-delivery of mailed checks.
// We allow landlords to request a credit report and/or a background check report from prospective tenants using the Service. When a landlord makes such a request, we will give the prospective tenant the option to order a single-use copy of his or her credit report and/or background check report and to share it with the requesting landlord. To provide this portion of the Service, we may ask for your Social Security Number and other personally identifying information (such as information about other creditors you have had), which we will share with our third-party credit reporting agency for the purposes of preparing and sharing reports and verifying your identity, and which we will not store (except than on a temporary basis) or use for any other purpose.
// Tenants may opt in to having their history of Cozy-processed rental payments furnished to third-party consumer credit-reporting agencies. To provide this service, we may securely transmit relevant tenant personal information to such agencies, including the tenant’s name, date of birth, address, payment amounts, and payment dates. Successful rent payments made by tenants while this feature is enabled may appear on credit reports provided by such agencies (including reports provided outside of the Service) and may be taken into account by such agencies in calculating credit scores. This feature may be disabled by tenants at any time. While this feature is enabled, months for which no payment has been made via Cozy will be reported to agencies as “no activity” (which is not an indication of failure to pay rent or of late payment). If a tenant disables this feature during the term of a current lease, the entire payment history associated with that lease may be deleted from reports about that tenant. Tenants may also have their entire Cozy rental payment history deleted from agencies’ reports upon request, though such removal will require processing by the agencies and may not take effect immediately. If you are a tenant and would like your payment history removed from credit reports about you, please contact us at support@cozy.co. Please note that, apart from giving tenants the ability to have their entire Cozy rent payment history removed from agency credit reports, Cozy does not control how or for how long payment history data covering periods during which this feature was enabled will appear on such credit reports.

// If you are a landlord requesting a credit report or a background check report from a prospective tenant, you certify that you are making such request, and will use such report, for the purpose of your own evaluation of the prospective tenant in connection with that tenant’s rental application and for no other purpose. If you are a tenant ordering a credit report or background check report, you certify that you are ordering such report, and will use such report, for the purpose of furnishing the report to a prospective landlord in connection with your rental application and for no other purpose. You agree not to share any credit report or background check report obtained through Cozy with anyone other than as allowed using the sharing mechanism on the Cozy site.
// Credit-report and background check-report functionality is provided by our third-party credit reporting agencies and may not be available for all users or at all times for various reasons, including reasons beyond our control or about which we may not have specific information. If you are unable to order, share, request, or view a credit report or background check report using the Service, you should contact the person requesting or being asked to provide the report via the contact information he or she has listed on Cozy to discuss the matter directly. You may also contact Cozy at support@cozy.co for assistance.
// We are not a credit bureau and do not control the contents of credit reports, (except to the limited extent described above with respect to opt-in rental payment reporting), including reports obtained through the Service. We are NOT responsible for the contents of any credit report or background check report or any credit score, whether correct or incorrect. Credit reports and background check reports can contain information that is incorrect, incomplete, or not up to date, and reports are not a substitute for carefully reviewing and independently verifying all information contained in a rental application and interviewing a prospective tenant. If you believe that any information contained in your credit report or background check report is inaccurate or incomplete, you have the right to dispute it. To dispute the contents of your credit report, please contact us via email at support@cozy.co or the telephone number displayed when your report is made available for viewing on Cozy. To dispute the contents of your background check report, please contact Checkr via email or telephone as described on your background check report.
// If you are a landlord requesting or accepting credit reports or background check reports, you specifically agree to familiarize yourself and comply with the Fair Credit Reporting Act and any applicable state laws governing the use of consumer reports. To the extent you rely in any way on the contents of a report in taking an adverse action against an applicant (such as denying an application or requiring a co-signer or higher deposit), you are required to furnish the applicant with an adverse action notice as described in this document, even if the contents of the report played only a small role in your decision. Note that for purposes of credit reports, Cozy is the applicable consumer reporting agency (CRA); for purposes of background check reports, Checkr is the applicable CRA.
// We use third-party identity verification services to help ensure that users are who they say they are prior to enabling certain features of the Services, such as electronic payments and the ordering and requesting of credit reports. Once your identity has been verified, we may also display a badge or other indicator to let other users know that you’ve successfully completed the verification process. However, we neither control nor guarantee the accuracy of any identity verification service (and none can prevent all scams), so you should always take the same precautions in dealing with others through our site that you would in person.
// We are not a real estate agent or broker. We don’t screen properties or listings or offer any guaranty or advice regarding the suitability of properties, landlords, or potential tenants. Nothing we say should be construed as such advice, and you agree not to rely on us in making decisions on, well, anything at all.
// Cozy’s tenant selection and confirmation features are designed to make communication between landlords and prospective tenants easier; neither they nor any other aspect of the Services are intended to create any kind of contract or other legal obligation between a landlord and a prospective tenant (or between anyone else, for that matter). Just like in the offline world, you should wait until a written rental or lease agreement is signed by both the landlord and tenant before relying on any tenancy you arrange using Cozy.
// You must be 18 years old to use Cozy. Banks require that.
// For right now, you must be a U.S. resident.
// You must be a human. Accounts registered by “bots” or automated methods of access are not permitted. No screen-scraping, reproducing, or aggregating the information or other content on our site.
// Use of the Service does not give you ownership of any intellectual property rights in the Service or any content posted on the Service. You own what you post on the Service (unless you copied it from someone else) but you grant us a license to copy, host, display, create derivative works from, publish, publicly perform, display, and distribute, and otherwise make use of in connection with providing the Service, all information and content you post so long as you have an active account. If you are a landlord and authorize us (by checking the appropriate box or boxes on our site) to syndicate your rental listing on third-party sites, you further agree that we may grant sublicenses to applicable third parties as necessary to facilitate such syndication.
// You must provide your full legal name, a valid email address, and any other information requested in order to complete the signup process. You are also responsible for keeping your email address up to date, so we can send you important notices.
// Your account may only be used by one person. A single account shared by more than one person is not permitted.
// You are responsible for keeping your password and account secure.
// You are responsible for the accuracy of everything that is posted or submitted under your account.
// We are not responsible for the accuracy of anything another user posts or submits to our site.
// We are not responsible for the conduct of users on or off the site. Use common sense when doing business with other users.
// You may not use the Service for any illegal purpose. You may not, in using the Service, violate any laws in your jurisdiction or ours, or infringe the rights of anyone (ours or third parties). We’d like to think this goes without saying, but you may not use the Service as part of any fraudulent scheme or transaction. If we have reason to suspect your account has been used for an unauthorized or unlawful purpose, you agree that we may share information about you, your account, and any of your transactions with law enforcement.
// If you are a landlord, you agree in particular that you are familiar with all applicable fair-housing and antidiscrimination laws and that you will adhere to them. You also agree that the contents of any listings you post on or link to from Cozy will be accurate and not misleading.
// Only landlords and their authorized property managers may set up or use landlord accounts, post rental listings, or accept applications via the Service. Third-party brokers (and anyone else not specifically authorized to accept and evaluate rental property applications on behalf of a property owner) may NOT use any of the Service’s landlord features. Deceptively posing as a landlord or authorized property manager in order to obtain leads or other personal information about tenants may constitute a criminal offense.
// Only actual tenants and prospective tenants may set up or use tenant accounts. Landlords, brokers, and other third parties may not submit rental applications on behalf of prospective tenants and may not configure, authorize, or initiate rent payments, credit or background check report orders, or other transactions on behalf of tenants or prospective tenants, even if the tenant or prospective tenant has given their permission.
// You may not abuse, harass, threaten, impersonate, or intimidate other Cozy customers. Don’t be creepy.
// You also may not post anything on Cozy that contains or links to sweepstakes (yes, that’s a thing, apparently), viruses, or malware.
// If you violate any of the Terms of Use, we may terminate your account without notice.
// We reserve the right to modify or terminate the Service for any reason, without notice, at any time.
// We reserve the right to alter these Terms of Use at any time. We’ll notify you on the site or via email of important changes, but you should also check back here from time to time if you care about such things. You’re free to cancel or discontinue using the Service at any time without penalty. Your continued use of the Service following a change to these Terms of Use constitutes acceptance of the change.
// If we change our pricing, we will give you at least 30 days’ notice, via the site or email.
// We reserve the right to refuse service to anyone for any reason (or no reason), and likewise to suspend or discontinue your use of the Service at any time for any reason or no reason. In particular (and just by way of example), we may terminate or suspend your account if you engage in fraudulent or illegal conduct, provide inaccurate, incomplete, false, or misleading information, or otherwise violate these Terms of Use or any of our policies, or if we determine, in our sole discretion, that your use of the Services poses an unacceptable credit or fraud risk to us.
// If you wish to cancel your account, you must do so via the Service web site or by sending us a letter in the mail. An email or phone request to cancel your account will not be processed.
// If you cancel your account, any or all of your information may be immediately (or at some point in the future) irrevocably deleted. You should save a copy of anything important before cancelling your account.
// Conversely, information you post or otherwise furnish to us, including personal information, may remain on our servers indefinitely (for example, as backups) after you delete it from public areas of the Services or request cancellation of your account.
// We won’t share your individual user or property information except as necessary to provide the Service or as otherwise described in these Terms of Use. We may share data with third parties for other purposes only in aggregated, anonymized form.
// If you are a landlord, you agree to indemnify, defend, and hold harmless us and our affiliates, agents, officers, employees, and vendors (including without limitation our third-party credit reporting agencies and their respective affiliates, agents, officers, and employees) from any claim, suit, action, or loss arising from or relating to your use of the Service or your violation of these Terms of Use (collectively, any “Claim”), including any liability or expense arising from claims, losses, damages, suits, judgments, civil or criminal fines or other penalties, litigation costs, and attorneys’ fees.
// If you are a landlord, by requesting or viewing a credit report using the Service, you additionally represent and warrant the following: (1) that you have never been involved, and will not become involved, in any credit fraud or other unethical business practice and that you will immediately discontinue all use of the Service (and any reports and other information obtained via the Service) and notify us if you are ever involved in any such fraud or practice; (2) that your request for any credit report using the Service, and your use of any credit report obtained through the Service, is lawful and solely for the purpose of evaluating a prospective tenant’s rental application; and (3) that you will not provide a copy of any report obtained through Cozy, or any information contained in such a report, to any other party. You agree to indemnify, defend, and hold harmless us and our affiliates, agents, officers, employees, and vendors (including without limitation our third-party credit reporting agencies and their respective affiliates, agents, officers, and employees) from any Claim arising out of or relating to your breach of the foregoing representations and warranties.
// Insurance products are sold exclusively through Cozy Insurance Services, LLC, a Florida limited liability company (doing business in California as Cozy Home Insurance Agency), as agent for Assurant, Inc.’s subsidiary insurance companies. Please direct all insurance billing, coverage, and claims inquiries to Assurant. All descriptions of coverage presented on the Services are summaries only; please obtain and refer to full policy documents for details on coverage limits, conditions, and exclusions. If you are a tenant eligible to purchase renters insurance through the Service, we may share your rental property address and your answers to any follow-up underwriting questions with Assurant during the rent payment setup process; no other personal information will be shared with Assurant unless you accept a quote (at which time we will share other information necessary to complete your policy purchase, including without limitation your name and contact information and any payment details you provide).

// Our lawyer made us use all caps for the following two big blocks of text (which you should still read). We promise, we’re not yelling at you.

// THE SERVICE IS PROVIDED “AS-IS” WITHOUT PROMISES OF ANY KIND. NEITHER WE NOR OUR SUPPLIERS WARRANT OR GUARANTEE UPTIME OR AVAILABILITY OF THE SERVICE, THAT THE SERVICE WILL MEET OR CONTINUE IN THE FUTURE TO MEET YOUR NEEDS, OR THAT WE WILL CONTINUE TO PROVIDE THE SERVICE OR ANY ASPECT OF THE SERVICE IN THE FUTURE. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES FOR THE SERVICE, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SEAWORTHINESS, AND NON-INFRINGEMENT.

// TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES, OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR TOTAL LIABILITY FOR ANY CLAIM RELATING TO THE SERVICES OR THESE TERMS OF USE, INCLUDING ANY CLAIM FOR ANY IMPLIED WARRANTY, IS LIMITED TO THE AMOUNT YOU PAID TO US TO USE THE SERVICES.

// Please refer to Cozy's DMCA page for instructions on how to provide notice of allegedly infringing material on cozy.co.
// If you violate any of these Terms of Use and we don’t take action right away, that doesn’t mean we are waiving any of our rights. If you want to sue us, you must do so in the state or federal courts located in Portland, Oregon, and you consent to personal jurisdiction in those courts. Oregon law (excluding Oregon's conflict of laws rules) will apply to any such disputes between you and Cozy. If any part of these Terms of Use are held to be invalid or enforceable, the rest of the Terms of Use will still apply.
// If you need to send us any legal notice (including notice that you believe another user is infringing your intellectual property or other rights), please do so via snail mail to the following address:
// Cozy Services Ltd. 3514 N. Vancouver Ave. 4th Floor, Portland, OR 97227 Attention: Legal

// Thanks for making it through all this. We try hard to be reasonable and clear. If you have any questions, contact us at terms@cozy.co.

// Last revised May 30, 2018.