import React from 'react';
import { Row, Col, Menu, Icon, List, Card, Anchor, Avatar, Input } from 'antd';
// import HeaderSearch from 'ant-design-pro/lib/HeaderSearch';
import Header from './header';
import Layout from './layout';
import { Link } from "gatsby"

const LegalLayout = (props) => {
    return (
        <Layout>
            <div style={{ paddingTop:'60px', paddingBottom:'60px', backgroundColor:'#fff',}}>
                <Row style={{ paddingTop:'50px', width:'100%'}}  justify='center'>
                    <Col sm={22} md={18} lg={16}>
                    <h2 style={{fontSize:'28px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'prewrap'}}>
                    <span style={{fontWeight:600}}>Legal</span>
                    </h2>
                    <Menu mode="horizontal" selectedKeys={[props.location.pathname]} style={{margin:'30px 0px'}}>
                    {/* <Menu.Item key="/legal/security/">
                        <Link to='/legal/security/'>Security</Link>
                    </Menu.Item> */}
                    <Menu.Item key="/legal/terms/" >
                        <Link to='/legal/terms/'>Service Agreement</Link>
                    </Menu.Item>
                    <Menu.Item key="/legal/privacy/" >
                        <Link to='/legal/privacy/'>Privacy</Link>
                    </Menu.Item>
                    </Menu>
                    </Col>
                    <Col sm={22} md={18} lg={16} style={{ backgroundColor:'#fff',}}>
                    {props.children}
                    </Col>
            </Row>
        </div>
    </Layout>
    )
}

export default LegalLayout;
